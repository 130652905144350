/* global Backbone:true, _:true, JSEncrypt:true, grecaptcha:true */

'use strict';


(function() {

  let app = {};

  let publicKey = $("#publicKey").text();

  let encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);


  let useCaptchas = false;

  //check if captcha area exists
  if ($("#captcha-area").length) {
    useCaptchas = true;
  }

  app.Login = Backbone.Model.extend({
    url: '/login/',
    defaults: {
      errors: [],
      errfor: {},
      requestTwoFactorCode: false,
      forcePasswordChange: false,
      resetPasswordToken: null,
      authenticationCode: null,
      failedTwoFactor: false,
      loginToken: null,
      rememberMe: false,
    }
  });

  app.LoginView = Backbone.View.extend({
    el: '#login',
    template: _.template($('#tmpl-login').html()),
    events: {
      'submit form': 'preventSubmit',
      'keypress [name="password"]': 'loginOnEnter',
      'click .btn-login': 'login',
      'click .btn-send-authentication': 'sendAuthentication',
      'click .btn-reset-password': 'resetPassword',
    },
    initialize: function() {
      this.model = new app.Login();
      this.listenTo(this.model, 'sync', this.render);
      this.render();
    },
    render: function() {


      if (this.model.attributes.forcePasswordChange === true) {
        $("#password-reset-area").removeClass("hidden");
        $("#login-area").addClass("hidden");
        $("#captcha-area").addClass("hidden");
        $("#two-factor-code-area").addClass("hidden");
        console.log("Password reset requested");
        return;
      }

      if (this.model.attributes.requestTwoFactorCode === true) {
        $("#login-area").addClass("hidden");
        console.log("Two factor code requested");
        $("#two-factor-code-area").removeClass("hidden");
        $("#captcha-area").addClass("hidden");
        return;
      }

      $("#login-area").removeClass("hidden");

      //we have to move the captcha to the footer, so it doesn't get overwritten
      $("#captcha-area").insertBefore("#hidden-captcha--footer-area");

      this.$el.html(this.template(this.model.attributes));
      $(document).find('[name="username"]').val(this.model.attributes.username);
      $(document).find('[name="password"]').val(this.model.attributes.password);
      this.$el.find('[name="username"]').focus();

      $("#captcha-area").insertBefore("#captcha-signin-area");

      if (this.model.attributes.rememberMe === true) {
        this.$el.find('[name="rememberMe"]').prop('checked', true);
      }


      if (this.model.attributes.failedTwoFactor === true && useCaptchas === true) {
        console.log("failed two factor");
        $("#captcha-area").removeClass("hidden");
        $("#two-factor-code-area").addClass("hidden");
        grecaptcha.reset();
        $(".alerts").append('<div class="alert alert-danger alert-dismissable"><button type="button" data-dismiss="alert" class="close">×</button>Code did not match, please try again</div>');

      }


    },
    preventSubmit: function(event) {
      event.preventDefault();
    },
    loginOnEnter: function(event) {
      if (event.keyCode !== 13) {
        return;
      }
      if ($(event.target).attr('name') !== 'password') {
        return;
      }
      event.preventDefault();
      this.login();
    },
    sendAuthentication: function() {
      let authenticationCode = $(document).find('[name="code"]').val();
      console.log(authenticationCode);

      var encrypted = encrypt.encrypt(JSON.stringify({

        username: $(document).find('[name="username"]').val(),
        password: $(document).find('[name="password"]').val(),
        time: new Date().getTime()
      }));

      this.model.attributes.username = "";
      this.model.attributes.password = "";


      this.model.save({
        payload: encrypted,
        authenticationCode: authenticationCode,
        loginToken: this.model.attributes.loginToken
      }, {
        success: function(model, response) {
          console.log("authentication save success");

          if (response.requestTwoFactorCode === true || response.failedTwoFactor === true) {
            console.log("two factor mode change");
            return;
          }


          if (response.forcePasswordChange === true) {
            console.log("Password needs to be changed");
            return;
          }

          if (useCaptchas === true) {
            grecaptcha.reset();
          }

          if (response.success) {

            $.LoadingOverlay("show");

            console.log("changing return url");

            var returnUrl = app.loginView.$el.find('[name="returnUrl"]').val();
            if (returnUrl === '/') {
              returnUrl = response.defaultReturnUrl;
            }
            location.href = returnUrl;
          }

          let newModel = {
            username: $(document).find('[name="username"]').val(),
            password: $(document).find('[name="password"]').val()
          };

          for (let prop in response) {
            newModel.prop = response[prop];
          }

          model.set(newModel);

        }
      });

    },


    resetPassword: function() {
      let authenticationCode = $(document).find('[name="code"]').val();
      console.log("resetting password");

      let password = $(document).find('[name="passwordreset"]').val();
      let passwordConfirm = $(document).find('[name="passwordresetconfirm"]').val();

      if (password !== passwordConfirm) {
        $(".alerts").append('<div class="alert alert-danger alert-dismissable"><button type="button" data-dismiss="alert" class="close">×</button>Passwords must match</div>');
        return;
      }

      var encrypted = encrypt.encrypt(JSON.stringify({
        username: $(document).find('[name="username"]').val(),
        password: $(document).find('[name="password"]').val(),
        newPassword: $(document).find('[name="passwordreset"]').val(),
        time: new Date().getTime()
      }));

      this.model.attributes.username = "";
      this.model.attributes.password = "";


      this.model.save({
        payload: encrypted,
        authenticationCode: authenticationCode,
        resetPasswordToken: this.model.attributes.resetPasswordToken,
        loginToken: this.model.attributes.loginToken
      }, {
        success: function(model, response) {
          console.log("password change save success");

          if (response.requestTwoFactorCode === true || response.failedTwoFactor === true) {
            console.log("two factor mode change");
            return;
          }


          if (response.forcePasswordChange === true) {
            console.log("Password needs to be changed");
            return;
          }

          if (useCaptchas === true) {
            grecaptcha.reset();
          }

          if (response.success) {

            $.LoadingOverlay("show");

            console.log("changing return url");

            var returnUrl = app.loginView.$el.find('[name="returnUrl"]').val();
            if (returnUrl === '/') {
              returnUrl = response.defaultReturnUrl;
            }
            location.href = returnUrl;
          }

          let newModel = {
            username: $(document).find('[name="username"]').val(),
            password: $(document).find('[name="password"]').val()
          };

          for (let prop in response) {
            newModel.prop = response[prop];
          }

          model.set(newModel);

        }
      });

    },


    login: function() {

      let captchaResonse = null;

      if (useCaptchas === true) {
        captchaResonse = grecaptcha.getResponse();

        if (captchaResonse == null || captchaResonse.length < 1) {
          console.log("No captcha");

          $(".alerts").append('<div class="alert alert-danger alert-dismissable"><button type="button" data-dismiss="alert" class="close">×</button>Captcha not filled out</div>');

          return;
        }
      }


      if (this.$el.find('[name="rememberMe"]').prop('checked') === true) {
        this.model.attributes.rememberMe = true;
      } else {
        this.model.attributes.rememberMe = false;
      }

      this.$el.find('.btn-login').attr('disabled', true);

      var encrypted = encrypt.encrypt(JSON.stringify({

        username: $(document).find('[name="username"]').val(),
        password: $(document).find('[name="password"]').val(),
        time: new Date().getTime()
      }));

      //don't send up unencrypted username or password
      this.model.attributes.username = "";
      this.model.attributes.password = "";

      this.model.save({
        payload: encrypted,
        response: captchaResonse
      }, {
        success: function(model, response) {

          console.log("login save success");

          if (response.requestTwoFactorCode === true || response.failedTwoFactor === true) {
            console.log("two factor mode change");
            return;
          }

          if (response.forcePasswordChange === true) {
            console.log("Password needs to be changed");
            return;
          }

          //
          if (useCaptchas) {
            grecaptcha.reset();
          }

          if (response.success) {
            console.log("changing return url");

            var returnUrl = app.loginView.$el.find('[name="returnUrl"]').val();
            if (returnUrl === '/') {
              returnUrl = response.defaultReturnUrl;
            }
            location.href = returnUrl;
          }

          let newModel = {
            username: $(document).find('[name="username"]').val(),
            password: $(document).find('[name="password"]').val()
          };

          for (let prop in response) {
            newModel.prop = response[prop];
          }

          model.set(newModel);

        }
      });
    }
  });

  $(document).ready(function() {
    app.loginView = new app.LoginView();
  });
}());